import * as React from 'react';

import { graphql, Link } from 'gatsby';
import { Layout } from '../components';

// markup
const Contacts = ({ data }) => {
	const pageTitle = 'Contactos';

	const contactRequestOnChange = (evt) => {
		if ((evt.target || evt.srcElement).checked) {
			document.body.classList.add('no-scroll');

			// request new CSRF Token
			fetch('https://cms.logiphorika.pt/api/v1/forms/token').then(res => res.json()).then((res) => {
				document.querySelector('#cf_csrf_token').value = res.token;
			});
		} else {
			document.body.classList.remove('no-scroll');

			// enable submit button
			document.querySelector('.modal--contact .content.form .btn[type="submit"]').disabled = false;

			// hide loading
			document
				.querySelector('.modal--contact').classList.remove('loading');

			// show modal form
			document
				.querySelector('.modal--contact .content.form')
				.classList.remove('d-none');
			document
				.querySelector('.modal--contact .content.form')
				.scrollTop = 0;

			// hide modal success message
			document
				.querySelector('.modal--contact .content.message-sent')
				.classList.add('d-none');
		}
	};

	const submitContactForm = (evt) => {
		evt.preventDefault();

		/** @var HTMLFormElement form */
		let form = evt.currentTarget,
			// form_fields = form.querySelectorAll('input,textarea,select'),
			form_data = new FormData(form),
			request = new XMLHttpRequest();

		// disable submit button
		document.querySelector('.modal--contact .content.form .btn[type="submit"]').disabled = true;

		// hide modal form
		document
			.querySelector('.modal--contact .content.form')
			.classList.add('d-none');

		// show modal sending message
		document
			.querySelector('.modal--contact .content.message-sending')
			.classList.remove('d-none');

		request.addEventListener('readystatechange', function(e) {
			if (this.readyState === 4) {
				let res = JSON.parse(this.response);
				// console.log(res);

				// hide modal sending message
				document
					.querySelector('.modal--contact .content.message-sending')
					.classList.add('d-none');

				if (res.result.status) {
					// clear form fields
					form.reset();

					// show modal success message
					document
						.querySelector('.modal--contact .content.message-sent')
						.classList.remove('d-none');
				} else {
					// enable submit button
					document.querySelector('.modal--contact .content.form .btn[type="submit"]').disabled = false;

					// show modal form
					document
						.querySelector('.modal--contact .content.form')
						.classList.remove('d-none');

					alert(res.result.message);
				}
			}
		});
		request.open('POST', 'https://cms.logiphorika.pt/api/v1/forms');
		request.send(form_data);

		return (evt.returnValue = false);
	};

	return (
		<Layout hasPreFooter={false} pageTitle={pageTitle}>
			<main
				data-name="layout"
				className="page-contacts container-fluid no-pre-footer"
			>
				<div className="main row">
					<div className="col-12">
						<div className="contact-info-bg"></div>
						<div className="bg-holder row mb-4">
							<div className="col-10 offset-1 col-lg-4 offset-lg-2 px-lg-3">
								<h1 className="pt-5 pb-3">
									Vamos <br />
									<strong>Conversar</strong>
								</h1>

								<label
									className="btn contact-button"
									htmlFor="cf_contact_request"
								>
									<span>Pedido de Contacto</span>
								</label>
							</div>
						</div>
						<div className="row">
							<div className="contact-info col-10 offset-1 col-lg-4 offset-lg-2 px-lg-3">
								<div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img
											src="/assets/img/icon_marker.svg"
											alt="Morada"
										/>
									</span>
									<span
										className="text"
										dangerouslySetInnerHTML={{
											__html: data.site.siteMetadata
												.contact.address,
										}}
									></span>
								</div>
								<div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img
											src="/assets/img/icon_envelope.svg"
											alt="Email"
										/>
									</span>
									<a
										href={
											"mailto:" +
											data.site.siteMetadata.contact.email
										}
										className="text"
									>
										{data.site.siteMetadata.contact.email}
									</a>
								</div>
								<div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img
											src="/assets/img/icon_phone_2.svg"
											alt="Contacto"
										/>
									</span>
									<span className="d-flex flex-column">
										<a
											href={
												"tel:" +
												data.site.siteMetadata.contact.phone.replace(
													/[^+\d]+/g,
													""
												)
											}
											className="text"
											style={{color:'#26A43A', fontWeight:'bold'}}
										>
											{data.site.siteMetadata.contact.phone}
										</a>
										<span style={{fontSize: '0.8em', opacity: 0.5}}>({data.site.siteMetadata.contact.phone.replace(/[^+\d]+/g, '').indexOf('+3519') !== -1 ? 'Chamada para rede móvel nacional' : 'Chamada para a rede fixa nacional'})</span>
									</span>
								</div>
								<div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img src="/assets/img/sn_tg.svg" alt="Telegram" />
									</span>
									<a href="https://t.me/logiphorika" target="_blank" rel="noreferrer" className="text" title="Telegram">Telegram</a>
								</div>
								<div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img src="/assets/img/sn_wa.svg" alt="WhatsApp" />
									</span>
									<a href={encodeURI('https://api.whatsapp.com/send?phone=351968477138&text=Pedido de Informações através do website')} target="_blank" rel="noreferrer" className="text" title="WhatsApp">WhatsApp</a>
								</div>
								{/* <div className="contact-info-item d-flex align-content-start">
									<span className="icon">
										<img src="/assets/img/sn_vb.svg" alt="Viber" />
									</span>
									<a href="viber://contact?number=%2B351968477138" target="_blank" rel="noreferrer" className="text" title="Viber">Viber</a>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</main>

			<input
				type="checkbox"
				id="cf_contact_request"
				name="contact_form"
				value="contact_request"
				onChange={contactRequestOnChange}
				autoComplete="off"
				hidden
			/>

			<div className="modal--contact" data-modal="true">

				<label
					htmlFor="cf_contact_request"
					className="backdrop"
				></label>

				<div className="content form container-fluid">

					<div className="row py-4">
						<label
							className="btn--close"
							htmlFor="cf_contact_request"
						>
							<div className="cross">
								<div></div>
								<div></div>
							</div>
						</label>
						<h2 className="title col-10 offset-1">
							Pedido de contacto
						</h2>
						<p className="subtitle col-10 offset-1">
							Fale connosco e conheça todos os nossos serviços
						</p>
					</div>
					<form
						method="POST"
						className="row"
						onSubmit={submitContactForm}
						action=""
					>
						<input type="hidden" name="_token" value="" id="cf_csrf_token" />
						<div className="input-holder col-10 offset-1">
							<label>
								<span className="text">Nome completo *</span>
								<input
									type="text"
									id="cf_name"
									name="name"
									required
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1 col-md-5">
							<label>
								<span className="text">Email *</span>
								<input
									type="email"
									id="cf_email"
									name="email"
									required
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1 col-md-5 offset-md-0">
							<label>
								<span className="text">Contacto *</span>
								<input
									type="text"
									id="cf_phone"
									name="phone"
									required
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1 col-md-5">
							<label>
								<span className="text">Empresa</span>
								<input
									type="text"
									id="cf_company"
									name="company"
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1 col-md-5 offset-md-0">
							<label>
								<span className="text">Cargo</span>
								<input
									type="text"
									id="cf_position"
									name="position"
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1">
							<label>
								<span className="text">Assunto de contacto *</span>
								<input
									type="text"
									id="cf_subject"
									name="subject"
									required
								/>
							</label>
						</div>
						<div className="input-holder col-10 offset-1">
							<label>
								<span className="text">Descrição da mensagem *</span>
								<textarea
									id="cf_message"
									name="message"
									required
								></textarea>
							</label>
						</div>
						<div className="input-holder col-10 offset-1 my-5">
							<input
								type="checkbox"
								id="cf_accept_terms"
								name="accept_terms"
								autoComplete="off"
								value="Sim"
								required
								hidden
							/>
							<label
								className="checkbox-holder"
								htmlFor="cf_accept_terms"
							>
								<span className="text">
									Li e concordo com os{" "}
									<Link to="/privacy-policy-and-cookies">
										Termos e Política de Privacidade e
										Cookies
									</Link>{" "}
									deste website
								</span>
							</label>
						</div>
						<div className="submit-holder col-10 offset-1 col-md-4 offset-md-7">
							<button
								className="btn"
								type="submit"
								name="submit"
								value="send"
							>
								Enviar
							</button>
						</div>
					</form>
				</div>

				<div className="content message-sending container-fluid d-none">
					<div className="row pt-4">
						<label
							className="btn--close"
							htmlFor="cf_contact_request"
						>
							<div className="cross">
								<div></div>
								<div></div>
							</div>
						</label>
						<h2 className="title col-10 offset-1">
							A enviar...
						</h2>
						<p className="subtitle col-10 offset-1"></p>
					</div>
					{/* <div className=" col-10 offset-1 col-md-4 offset-md-7">
						<label
							className="btn"
							htmlFor="cf_contact_request"

						>
							Fechar
						</label>
					</div> */}
				</div>

				<div className="content message-sent container-fluid d-none">
					<div className="row pt-4">
						<label
							className="btn--close"
							htmlFor="cf_contact_request"
						>
							<div className="cross">
								<div></div>
								<div></div>
							</div>
						</label>
						<h2 className="title col-10 offset-1">
							Mensagem enviada com sucesso
						</h2>
						<p className="subtitle col-10 offset-1">
							Responderemos com a maior brevidade possivel.
						</p>
					</div>
					<div className=" col-10 offset-1 col-md-4 offset-md-7">
						<label
							className="btn"
							htmlFor="cf_contact_request"

						>
							Fechar
						</label>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	{
		site {
			siteMetadata {
				contact {
					address
					email
					phone
				}
			}
		}
	}
`;

export default Contacts;
